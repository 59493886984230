<template>
    <div>
        <span v-for="(item, index) in breadcrumbs">
            <template v-if="index > 0"><i class="fas fa-chevron-right mx-2" style="font-size: 0.7em"></i></template>
            {{ item.name }}
        </span>
    </div>
  <!--<CBreadcrumb class="">
    <CBreadcrumbItem
      v-for="item in breadcrumbs"
      :key="item"
      :active="item.active"
    >
      {{ item.name }}
    </CBreadcrumbItem>
  </CBreadcrumb>-->
</template>

<script>
import { onMounted, ref } from 'vue'
import router from '@/router'

export default {
  name: 'AppBreadcrumb',
  setup() {
    const breadcrumbs = ref()

    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map((route) => {
        return {
          active: route.path === router.currentRoute.value.fullPath,
          name: route.name,
          path: `${router.options.history.base}${route.path}`,
        }
      })
    }

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    return {
      breadcrumbs,
    }
  },
}
</script>
